<template>

  <v-dialog
    width="750"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Move item <span class="font-weight-black">Item Name</span></span>
        <span>for <span class="font-weight-black">Board Name</span> to another meeting</span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>
            <v-select
              label="Meeting"
              outlined
              small
              hide-details
              v-model="meetingSelected"
              :items="meetingList"
            ></v-select>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingPacketMoveToMeeting',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    meetingSelected: null,
    meetingList: ['Name of Meeting 1', 'Name of Meeting 2', 'Name of Meeting 3'],
    regionList: ['Name of Region 1', 'Name of Region 2', 'Name of Region 3'],
    itemTypeList: ['Name of Item Type 1', 'Name of Item Type 2', 'Name of Item Type 3']
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
