<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-btn
            class="mr-3 secondary--text pa-0 pr-3"
            text
            @click="$router.push({ name: 'Meetings' })"
          >
            <v-icon size="30">mdi-chevron-left</v-icon>
            Meetings
          </v-btn>
          <h2 class="darkGrey--text d-flex flex-column custom-section-title">
            <span>Packet of <span class="font-weight-black">Meeting Name</span></span>
            <p class="text-body-1">for Board Name</p>
          </h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Board Members
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-bind="attrs"
                v-on="on"
                class="mr-3"
                @click="openModal('download')"
              >
                <v-icon size="32">mdi-download</v-icon>
              </v-btn>
            </template>
            <span>
              Download Packet
            </span>
          </v-tooltip>
        </span>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="7">
        <v-card
          v-for="(item,n) in packetSample"
          :key="n"
          class="mb-4"
        >
          <v-card-text>
              <v-row
              >
                <v-col class="py-4">

                  <v-row class="d-flex py-4">
                    <v-col cols="1" class="d-flex align-center">
                      <span v-if="item.type=== 'agenda'">&nbsp;</span>
                      <v-icon color="lightGrey" v-if="item.type!== 'agenda'">mdi-drag-horizontal-variant</v-icon>
                    </v-col>
                    <v-col class="d-flex align-center">
                      <v-icon class="mr-6" color="secondary" v-if="item.type === 'agenda'">mdi-format-list-numbered</v-icon>
                      <v-icon class="mr-6" color="secondary" v-else>mdi-table</v-icon>
                      <span class="font-weight-bold text-uppercase">{{ item.name }}</span>
                    </v-col>
                    <v-col cols="3" class="text-right">

                      <span v-if="item.type === 'agenda'">

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon size="20">
                                mdi-eye-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                              View Agenda
                            </span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon size="20">
                                mdi-download
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                              Download Agenda
                            </span>
                        </v-tooltip>

                      </span>

                      <span v-else>

                        <v-menu transition="slide-x-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                            >
                              <v-icon size="20">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item-group>
                              <v-list-item
                                v-for="(itemMenu, index) in regionsMenu"
                                :key="index"
                                @click="openModal(itemMenu.actions, itemMenu.optional)"
                                dense
                              >
                                <v-list-item-icon>
                                  <v-icon
                                    :color="itemMenu.type? itemMenu.type:'darkGrey'"
                                  >
                                    {{ itemMenu.icon }}
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title
                                    :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                                  >
                                    {{ itemMenu.text }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              depressed
                              icon
                              @click="item.collapsed = !item.collapsed"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="secondary" v-if="!item.collapsed" style="opacity: 0.3">mdi-dns</v-icon>
                              <v-icon color="secondary" v-if="item.collapsed">mdi-dns</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            <span v-if="!item.collapsed">Show Items</span>
                            <span v-else>Hide Items</span>
                          </span>
                        </v-tooltip>

                      </span>

                    </v-col>
                  </v-row>

                  <v-expand-transition>
                    <span v-show="item.collapsed" class="packet-items">

                      <v-divider class="mb-8 mt-2"></v-divider>

                      <v-row v-for="(element,n) in item.items" :key="n" class="pb-6 packet-item">
                        <v-col>

                          <v-row>
                            <v-col cols="1">
                              &nbsp;
                            </v-col>
                            <v-col cols="1">
                              <v-icon color="lightGrey" v-if="item.items.length > 1">mdi-drag-horizontal-variant</v-icon>
                            </v-col>
                            <v-col>

                              <span class="d-flex">
                                <span class="text-caption">
                                  {{ element.name.slice(0,textCut) }}
                                  <v-tooltip
                                    top
                                    class="tooltip-no-opacity"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-if="element.name.length > textCut"
                                        width="24"
                                        height="18"
                                        min-width="24"
                                        min-height="18"
                                        class="ml-2"
                                        depressed
                                        color="lightGrey"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon>mdi-dots-horizontal</v-icon>
                                      </v-btn>
                                    </template>
                                    <span class="text-caption">
                                      <v-card elevation="0" width="600" color="transparent" class="white--text pa-4">
                                        {{ element.name }}
                                      </v-card>
                                    </span>
                                  </v-tooltip>
                                </span>
                              </span>

                            </v-col>
                            <v-col class="text-right" style="max-width: 190px">

                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    color="darkGrey"
                                    size="18"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon size="20">
                                      mdi-eye-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  View Item
                                </span>
                              </v-tooltip>

                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    color="darkGrey"
                                    size="18"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon size="20">
                                      mdi-download
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                    Download Item
                                  </span>
                              </v-tooltip>

                              <v-menu transition="slide-x-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                  >
                                    <v-icon size="20">mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item-group>
                                    <v-list-item
                                      v-for="(itemMenu, index) in itemsMenu"
                                      :key="index"
                                      @click="openModal(itemMenu.actions, itemMenu.optional)"
                                      dense
                                    >
                                      <v-list-item-icon>
                                        <v-icon
                                          :color="itemMenu.type? itemMenu.type:'darkGrey'"
                                        >
                                          {{ itemMenu.icon }}
                                        </v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                                        >
                                          {{ itemMenu.text }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item-group>
                                </v-list>
                              </v-menu>

                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    depressed
                                    icon
                                    @click="element.collapsed = !element.collapsed"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="secondary" v-if="!element.collapsed" style="opacity: 0.3">mdi-paperclip</v-icon>
                                    <v-icon color="secondary" v-if="element.collapsed">mdi-paperclip</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  <span v-if="!element.collapsed">Show Attachments</span>
                                  <span v-else>Hide Attachments</span>
                                </span>
                              </v-tooltip>

                            </v-col>
                          </v-row>

                          <v-expand-transition>
                          <span v-show="element.collapsed" class="packet-attachments">

                            <v-row v-for="(attachment,m) in element.attachments" :key="m" class="packet-attachment">
                              <v-col cols="2">
                                &nbsp;
                              </v-col>
                              <v-col cols="1" class="d-flex align-center">
                                <v-icon color="lightGrey" v-if="element.attachments.length > 1">mdi-drag-horizontal-variant</v-icon>
                              </v-col>
                              <v-col class="d-flex align-center">
                                <v-icon color="secondary" size="16" class="mr-4">mdi-file-outline</v-icon>
                                <span class="text-caption">{{ attachment.name }}</span>
                              </v-col>
                              <v-col cols="3" class="text-right packet-attachment-actions">

                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      color="darkGrey"
                                      size="18"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon size="20">
                                        mdi-eye-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    View Attachment
                                  </span>
                                </v-tooltip>

                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      color="darkGrey"
                                      size="18"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon size="20">
                                        mdi-download
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                      Download Attachment
                                    </span>
                                </v-tooltip>

                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      color="darkGrey"
                                      size="18"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="openModal('deleteAttachment')"
                                    >
                                      <v-icon size="20" color="important">
                                        mdi-delete
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                      Delete
                                    </span>
                                </v-tooltip>

                              </v-col>
                            </v-row>

                          </span>
                          </v-expand-transition>

                        </v-col>
                      </v-row>

                    </span>
                  </v-expand-transition>

                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card
          color="darkGrey"
          elevation="0"
          class="py-14 d-flex justify-center"
          style="position: -webkit-sticky; position: sticky; top: 100px;"
        >
          <v-card
            color="white"
            tile
            style="width: 4.25in; height: 5.5in"
          >
            &nbsp;
          </v-card>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="downloadingPacket"
      timeout="-1"
      class="custom-snackbar"
    >
      <span class="d-flex align-center">
        <v-progress-linear
          color="secondary"
          rounded
          value="60"
          v-if="downloadingPacketMinimized"
        ></v-progress-linear>
        <v-progress-circular
          :value="60"
          :width="3"
          color="secondary"
          v-if="!downloadingPacketMinimized"
        ></v-progress-circular>
        <span
          class="d-flex flex-column ml-4"
          v-if="!downloadingPacketMinimized"
        >
          <span class="text-uppercase text-caption">Preparing Draft Packet</span>
          <span class="font-weight-bold">Name of Meeting</span>
        </span>
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="viewDownloadProgress"
          v-if="!downloadingPacketMinimized"
        >
          <v-icon size="22">mdi-eye</v-icon>
        </v-btn>
        <v-btn
          icon
          v-bind="attrs"
          @click="downloadingPacketMinimized = true"
          v-if="!downloadingPacketMinimized"
        >
          <v-icon size="14">mdi-window-minimize</v-icon>
        </v-btn>
        <v-btn
          icon
          x-small
          v-bind="attrs"
          @click="downloadingPacketMinimized = false"
          v-if="downloadingPacketMinimized"
        >
          <v-icon size="14">mdi-window-maximize</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <meeting-packet-download v-model="modals.download" @callDownloadPacket="downloadPacket" v-if="modals.download"></meeting-packet-download>
    <meeting-packet-download-progress v-model="modals.downloadProgress" @callDownloadPacket="downloadPacket" v-if="modals.downloadProgress"></meeting-packet-download-progress>

    <meeting-packet-region-delete v-model="modals.deleteRegion"></meeting-packet-region-delete>
    <meeting-packet-item-delete v-model="modals.deleteItem"></meeting-packet-item-delete>
    <meeting-packet-attachment-delete v-model="modals.deleteAttachment"></meeting-packet-attachment-delete>
    <meeting-packet-park v-model="modals.park"></meeting-packet-park>
    <meeting-packet-add-note v-model="modals.note"></meeting-packet-add-note>
    <meeting-packet-add-comment v-model="modals.comment"></meeting-packet-add-comment>
    <meeting-packet-move-to-meeting v-model="modals.move"></meeting-packet-move-to-meeting>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import MeetingPacketDownload from '@/components/Meetings/MeetingPacketDownload'
import MeetingPacketDownloadProgress from '@/components/Meetings/MeetingPacketDownloadProgress'
import MeetingPacketRegionDelete from '@/components/Meetings/MeetingPacketRegionDelete'
import MeetingPacketItemDelete from '@/components/Meetings/MeetingPacketItemDelete'
import MeetingPacketAttachmentDelete from '@/components/Meetings/MeetingPacketAttachmentDelete'
import MeetingPacketPark from '@/components/Meetings/MeetingPacketPark'
import MeetingPacketAddNote from '@/components/Meetings/MeetingPacketAddNote'
import MeetingPacketAddComment from '@/components/Meetings/MeetingPacketAddComment'
import MeetingPacketMoveToMeeting from '@/components/Meetings/MeetingPacketMoveToMeeting'

export default Vue.extend({
  name: 'MeetingPacket',
  components: {
    Portal,
    MeetingPacketDownload,
    MeetingPacketDownloadProgress,
    MeetingPacketRegionDelete,
    MeetingPacketItemDelete,
    MeetingPacketAttachmentDelete,
    MeetingPacketPark,
    MeetingPacketAddNote,
    MeetingPacketAddComment,
    MeetingPacketMoveToMeeting
  },
  data: () => ({
    modeType: '',
    downloadingPacket: false,
    downloadingPacketMinimized: false,
    textCut: 150,
    modals: {
      download: false,
      downloadProgress: false,
      park: false,
      deleteRegion: false,
      deleteItem: false,
      deleteAttachment: false,
      comment: false,
      note: false,
      move: false
    },
    breadcrumbsItems: [
      {
        text: 'Meetings',
        disabled: false,
        href: ''
      },
      {
        text: 'Packet of Meeting Name',
        disabled: true,
        href: '/boards'
      }
    ],
    regionsMenu: [
      { icon: 'mdi-pencil', text: 'Edit', actions: 'edit' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'deleteRegion' }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit', actions: 'edit' },
      { icon: 'mdi-undo-variant', text: 'Move to Another Meeting', actions: 'move' },
      { icon: 'mdi-note-text-outline', text: 'Add Note', actions: 'note' },
      { icon: 'mdi-message-text-outline', text: 'Add Comment', actions: 'comment' },
      { icon: 'mdi-sign-caution', text: 'Park Item', type: 'important', actions: 'park' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'deleteItem' }
    ],
    headers: [
      { text: 'Label', value: 'name' }
    ],
    packetSample: [
      {
        name: 'Agenda Sample',
        type: 'agenda'
      },
      {
        name: 'Region Sample 1',
        type: 'region',
        collapsed: false,
        items: [
          {
            name: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr…',
            collapsed: false,
            attachments: [
              { name: 'Attachment 1' },
              { name: 'Attachment 2' },
              { name: 'Attachment 3' },
              { name: 'Attachment 4' },
              { name: 'Attachment 5' }
            ]
          },
          {
            name: 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr…',
            collapsed: false,
            attachments: [
              { name: 'Attachment 1' }
            ]
          },
          {
            name: 'Sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr…',
            collapsed: false,
            attachments: [
              { name: 'Attachment 1' },
              { name: 'Attachment 2' }
            ]
          }
        ]
      },
      {
        name: 'Region Sample 2',
        type: 'region',
        collapsed: false,
        items: [
          {
            name: 'Let justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr…',
            collapsed: false,
            attachments: [
              { name: 'Attachment 1' },
              { name: 'Attachment 2' },
              { name: 'Attachment 3' },
              { name: 'Attachment 4' },
              { name: 'Attachment 5' },
              { name: 'Attachment 6' },
              { name: 'Attachment 7' },
              { name: 'Attachment 8' }
            ]
          }
        ]
      }
    ]
  }),
  methods: {
    openModal (item, optional) {
      if (optional) this.modeType = optional
      this.modals[item] = true
    },
    downloadPacket () {
      this.downloadingPacket = true
    },
    viewDownloadProgress () {
      this.openModal('downloadProgress')
      this.downloadingPacket = false
    }
  }
})
</script>
<style>

  .packet-attachment {
    min-height: 60px;
  }
  .packet-item:hover {
    background-color: rgba(0,0,0,0.05);
  }
  .packet-attachment:hover {
    background-color: rgba(0,0,0,0.05);
  }
  .packet-attachment-actions {
    display: none;
  }
  .packet-attachment:hover .packet-attachment-actions {
    display: block;
  }

</style>
